.App {
  text-align: center;
  min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

/* CSS Custom Properties */
:root {
  --white: rgb(204, 204, 228);

  --tan-2: #e7e2dd;
  --tan-3: #e2d9d1;
  --tan-4: #d3c1ae;
  --orange: #f67f00;
  --text-black: #3d3d3d;
  --text-dark: #0a076488;
  --title-light: #dfddf8;
}
 
*,
*:before,
*:after {
  box-sizing: border-box;
} 

body {
  margin: 0;
  padding-top: 80px; /*because of fixed navbar*/
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #6161ac;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 2 1'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1' gradientTransform='rotate(227,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%236161AC'/%3E%3Cstop offset='1' stop-color='%2390AEE0'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1' gradientTransform='rotate(187,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%23644CB9' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23644CB9' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2' gradientTransform='rotate(13,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%23644CB9' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23644CB9' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' fill='url(%23a)' width='2' height='1'/%3E%3Cg fill-opacity='0.46'%3E%3Cpolygon fill='url(%23b)' points='0 1 0 0 2 0'/%3E%3Cpolygon fill='url(%23c)' points='2 1 2 0 0 0'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  /* padding: 2vmin; */
  height: 100vh;
}



  @media (orientation: landscape) {
  .home-pic-text{
    top: 10%;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  .map{
    width:50%
  }
    
    
    
    
    
  .emailUs-form{
    width:50%
  }
   .info, .quals>div{
    width:70%
  }
   .quals-list{
    text-align: center;
  }
}

@media (orientation:portrait){
   .emailUs-form{
    width:80%
  }
  .info, .quals>div{
    width:90%
  }
  .quals-list{
    text-align: start;
  }
  .gallery{
      grid-template-rows: repeat(10, 10vw);
  }
}

@media screen and (max-width: 819px) {
  body {
    padding-top: 120px;
    /* min-width: 100%; */
  }

  .navBar {
    flex-direction: column;
    align-items: center;
    
  }
  .admin-navbar{
    font-size: 0.5rem;
  }

  .navbar-brand{
    font-size: 1rem;
    /* display: none; */
  }





  .homePic>.home-pic-text{

    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, 0%);
    /* height: min-content; */
  }

  .homePic-faded>.home-pic-text{

    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  


  .main-info {
    flex-direction: column;
  }
  .contactUs-page{
    flex-direction: column;
  }

  .contactUs-img>img{
    margin-left: 10px;
    width: 90vw;
    border-radius: 10px;
  }

}

@media screen and (max-width: 400px) and (orientation:portrait){
  .navbar-brand{display: none}
}



@media screen and (max-width: 702px) {
  .add-container {
    flex-direction: column;
  }
  .ad {
    padding: 10px;
    margin-left: auto;
    min-width: 100%;
  }
  .ad-hidden{
    display: none;
  }
  .directions {
    margin-left: auto;
    min-width: 100%;
  }
  .ad {
    align-self: center;
  }

  .ad > span {
    width: fit-content;
  }
  .ad > span > a > button {
    width: fit-content;
    height: fit-content;
  }
}

@media screen and (max-width: 402px) {
  body {
    padding-top: 150px;
  }

}

@media screen and (max-width: 430px) {
  .bottom-buttons {
    flex-direction: column;
    align-items: center;
  }

}



@media screen and (max-width: 1300px) {
  .listing-title {
    white-space: wrap;
  }

}

@media screen and (min-width:390px ){
  .homePic-faded>.home-pic-text{
    /* height: fit-content; */
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  .homePic>.home-pic-text{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  
}



a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#root {
  height: 100%;
}
.hidden{
  display: none;
}
.shown{
  display:block;
}
.text{
  font-size: larger;
  color:var(--text-black);
  text-align: left;
}
.center{
  text-align: center;
}







/*NAVBAR*/
.navBar {
  padding: 5px 40px;
  font-weight: bold;
  display: flex;
  max-width: 100vw;

  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  background-color: rgb(204, 204, 228);
}
#admin-welcome-msg{
  font-size: 25px;
  font-weight: 500;
  color: white;
}
.nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.nav-links > li {
  padding: 0px 0.75rem;
  white-space: nowrap;
}

.navbar-logo{
  display: flex;
  flex-direction: row;
}

.navbar-brand,
.navbar-text {
  color: #0a076488;
}

.navbar-text:hover,
.navbar-brand:hover {
  color: whitesmoke;
}

.brand-img {
  margin-bottom: 10px;
  margin-right: 10px;
}


/*Footer*/
.footer{
  color: rgb(231, 207, 164);
  
  margin-top:auto;
  width: 100%;
  height:fit-content;

  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
  rgba(0, 0, 0, 0.22) 0px 15px 12px;
  background-color: rgba(192, 192, 213,.3);
  
}

.footer>a{
  color:rgb(236, 228, 248);
  text-decoration: underline;
  font-weight: 500;
}


.copy>a{
  color:rgb(236, 228, 248);
  font-weight: 600;
}

.social>i{
  color: white;
}
.error{
position: absolute;
  transform: translate(-50%,-50%);
  top:50%;
  left:50%;
  width:90%
}












/*BUTTONS*/
.create-btn {
  margin: 10px;
  color: white;
  background-color: #5e3389;
  border: none;
  border-radius: 10px;
  height: 50px;
  width: 140px;
  font-size: large;
  font-weight: 600;
  white-space: nowrap;
  text-align: center;
 
  
}

.create-btn:hover {
  background-color: #337c89;
}

.create-btn:active {
  background-color: #337c89;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.btn-add {
  color: white;
  background-color: #5e3389;
  border: none;
  border-radius: 10px;
  height: 35px;
  width: 140px;
  font-size: large;
  font-weight: 600;

  margin-top: 10px;
  white-space: nowrap;
  
}

.delete-btn {
  margin: 10px;
  color: white;
  background-color: #93542d;
  border: none;
  border-radius: 10px;
  height: 50px;
  width: 140px;
  font-size: large;
  font-weight: 600;
  text-align: center;

  white-space: nowrap;

}

.delete-btn:hover {
  background-color: #932d2d;
}

.create-btn:active {
  background-color: #932d2d;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.btn-add {
  color: white;
  background-color: #5e3389;
  border: none;
  border-radius: 10px;
  height: 35px;
  width: 140px;
  font-size: large;
  font-weight: 600;

  margin-top: 10px;
  white-space: nowrap;
  height: fit-content;
  width: fit-content;
}














/*HOMEPAGE*/

.homepageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 1vh;
}

.add-container {
  display: flex;
  align-items: center;
  row-gap: 10vw;
  justify-content: center;

  width: 100%;

  padding: 0 0.5vw;

  margin-bottom: 10px;
}

.single-direction {
  color: #e6e6f2f3;
  font-weight: bolder;
  font-size: large;
  height:8vh;
  font-size: 1em;
}

.single-direction > a {
  color: rgb(243, 234, 218);
  text-decoration: underline;
}

.directions {

  color: #e6e6f2f3;
  font-weight: bolder;
  font-size: large;
  /* margin-right:auto;
  margin-left: auto; */
  text-align: center;
}

.directions > a {
  color: rgb(243, 234, 218);
  text-decoration: underline;
}
.ad-hidden{
  margin-left: auto;
  width: 25vmax;
  visibility: hidden;
  /* display: none; */
}

.ad {
  margin-right: auto;
  font-weight: bold;
  color: var(--text-dark);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(94, 87, 87, 0.3) 0px 18px 36px -18px inset;

  width: 25vmax;

  border-radius: 15px;
  padding: 10px;
  /* margin-right: 200px; */

  background-color: rgba(196, 193, 238, .7);
  animation: wiggle 3s linear infinite;
}

@keyframes wiggle {
  0%,
  7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-2deg);
  }
  20% {
    transform: rotateZ(2deg);
  }
  25% {
    transform: rotateZ(-1deg);
  }
  30% {
    transform: rotateZ(1deg);
  }
  35% {
    transform: rotateZ(-1deg);
  }
  40%,
  100% {
    transform: rotateZ(0);
  }
}
.homePic {
  position: relative;
}

.homePic > img {

  filter: brightness(65%);
  height: 70vh;
  width: 100vw;
  border-radius: 5px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  object-fit: cover;
  object-position: 28% 0;
}

.homePic>.home-pic-text{
  /* height: 100px; */
  color:rgb(215, 202, 248);
  font-weight: 850;
  width: 90%;
}




.title-main{

  font-size:6vmax;
  width: 98%
}


.homePic-faded{
  position: relative;
  
}
.homePic-faded>img{
  filter: brightness(55%);
  height: 70vh;
  width: 100vw;
  border-radius: 5px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  object-fit: cover;
  object-position: 28% 0;
}

.homePic-faded>.home-pic-text{
  color:rgb(215, 202, 248);
  font-weight: 850;
  width: 90%
}

.home-btn{
  margin: 10px;
  padding: 7px;
  display:inline-block;
  white-space: nowrap;
  color: white;
  background-color: #5e3389;
  border: none;
  border-radius: 10px;
  height: fit-content;
  width: fit-content;
  font-size: large;
  font-weight: 600;

}






















/*FORM*/
.form-container {
  margin-top: 70vh;
  position: absolute;
  left: 50%;

  transform: translate(-50%, -50%);

  width: 60vw;
  padding: 3vmin;
  background-color: var(--white);
  /* border: .1vmin solid var(--tan-3);*/
  border-radius: 1vmin;
}

p.error-message {
  color: var(--orange);
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
  color: var(--text-light);
}

legend {
  font-weight: bold;
  color: #0a076488;
}

label {
  font-size: 2vmin;
  color: #0a076488;

  display: flex;
  align-self: center;
}

input {
  padding: 5px;
  font-size: 2vmin;
  border: 1px solid var(--tan-3);
  border-radius: 3px;
  color: var(--text-dark);
  background-image: none !important; /* prevent lastpass */
  outline: none;
  display: flex;
  align-self: center;
}

input:focus {
  border-color: var(--orange);
}

textarea {
  width: 100%;
  height: 100px;
  font-size: 2vmin;

  border: 0.1px solid var(--tan-3);
  border-radius: 3px;
  color: var(--text-dark);
  background-image: none !important; /* prevent lastpass */
  outline: none;
}
textarea:focus {
  border-color: var(--orange);
}

.form-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.form-title > input {
  width: 90%;
}

.prices {
  width: 90%;
  display: flex;
  flex-wrap: wrap;

  justify-content: space-between;
  margin-left: 3vw;
  margin-bottom: 20px;
}

.small-textarea {
  height: 50px;
}

button[type="submit"] {
  grid-column: span 2;
  margin: 1vmin 0 0;
  width: 20vw;
  align-self: center;

  border: none;
  border-radius: 3px;

  background-color: #9277d0;
  color: white;
  font-size: large;
  font-weight: 600;

  white-space: nowrap;
  height: fit-content;
  width: fit-content;
}

.photos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.file-input-wrapper label:hover {
    background-color: #b2cfec;
    cursor: pointer;
}

.file-input-wrapper{
  display: flex;
  flex-direction: row;
}


input[type="file"] {
  border: none;
    padding:6px 0 0 7px;
    &::-webkit-file-upload-button {
        display: none;
    }
    &::file-selector-button {
        display: none;
    }
}

.input-group-text{
  margin-top: 10px;
  width:4vw;
  min-width: fit-content;
  cursor: pointer;
}

.fileBtn {
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #dd7dbe;
}

button[type="submit"]:active {
  background-color: #d5afc9;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

/*List of Availabilities*/

.listings-ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  width:100vw;
}

.listing-ad {
  display: flex;
  align-items: center;

  border-radius: 15px;
  padding: 10px;
  margin: 20px;
  min-width: 45vw;
  /* max-width: 50vw; */
  /* width: 50vw; */
  color: var(--text-dark);

  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  background-color: rgba(196, 193, 238, .7);
}

.listing-ad-img > img {
  display: flex;
  flex-direction: column;
  height: 100px;
  border-radius: 8px;
}

.listing-title {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  width: 100%;
  text-align: left;
}
.listing-title>h3{
  text-wrap: balance;
}

.price {
  color: #598c58;
}
.rent {
  color: var(--text-dark);
  font-weight: bold;
}

.lastUpdate {
  color: rgb(233, 230, 230);
  /* align-self: flex-end; */
}
.forestLink{
  color:rgb(24, 106, 24);
  text-decoration: underline;
}



.visitors_sorting_form{
  width:50%;
  align-self: center;
}



#Title_visitor_cities_ul{
  margin-top: 5dvh;
  align-self: flex-start;
  margin-left: 5%;
}

#visitor_cities_ul{
  max-width:90%;
  max-height: 30dvh;
  align-self: flex-start;
  margin-left: 5%;


  display:flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content: space-between;

  overflow-y: auto;

  margin-bottom: 5dvh;
  color: var(--title-light) 

}
#visitor_cities_ul>li{
  margin: 5px 20px 5px 0px;
  font-weight: bold;
}


















/*Show listing Page*/

.main-info {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

/*Image Stack*/
.stack-container {
  margin-left: 25px;
  margin-bottom: 50px;
}
.stack {
  cursor: pointer;
  margin: 50px auto;
  /* position: relative; */
  float: left;
}
.stack:before {
  background: #eff4de;
  top: -10px;
  left: -15px;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  transform: rotate(-5deg);
}
.stack:after {
  background: #768590;
  top: 5px;
  left: 0px;
  -webkit-transform: rotate(4deg);
  -moz-transform: rotate(4deg);
  -o-transform: rotate(4deg);
  -ms-transform: rotate(4deg);
  transform: rotate(4deg);
}
.stack,
.stack:before,
.stack:after {
  border: 6px solid #fff;
  width: 250px;
  height: 180px;
  -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
.stack:before,
.stack:after {
  content: "";
  position: absolute;
  z-index: -1;
}
.stack > span {
  position: relative;
  top: 10px;
  margin: 0 auto;
  width: 90%;
  color: var(--text-black);
  font-size: 0.8em;
  padding: 10px;
  display: block;
  background: #fafafa;
  text-align: center;
  z-index: -10;
  -webkit-transform: rotate(2deg);
  -moz-transform: rotate(2deg);
  -o-transform: rotate(2deg);
  -ms-transform: rotate(2deg);
  transform: rotate(2deg);
  -webkit-border-radius: 3px;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27),
    0 0 60px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.06) inset;
}
.stack > img {
  border-radius: 5px;
}
.info {
  display: flex;
  flex-direction: column;

  color: #3d3d3d;
  /* width: 70%; */
  text-align: initial;

  background-color: rgba(196, 193, 238, .7);
  border-radius: 10px;
  padding: 10px;
}

.info-title {
  color: var(--text-dark);
}

.sub-title {
  font-weight: bold;
  font-size: large;
}

.title {
  color: #dfddf8;
  font-weight: bold;
}
.available {
  align-self: center;
  color: green;
  font-weight: bold;
}
.not-available {
  color: red;
  font-weight: bold;
}

.quals {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 20px;
  color: #dfddf8;
  text-align: center;
}

.quals > h2 {
  font-weight: bold;
  text-align: center;
}

.quals > div {
  padding: 30px 10px;
  color: var(--text-black);
  font-weight: 500;
  background-color: rgba(196, 193, 238, .7);
  border-radius: 10px;
  /* width: 70%; */
}

.quals-list{
  list-style:inside;
  /* text-align: center; */
  color: var(--text-black);
  font-weight: 500;

}
.quals-list>li{
  color: var(--text-black);
  margin-top: 5px;
}

.bottom-buttons {
  display: flex;
  justify-content: space-evenly;
}
.bottom-buttons > button {
  height: 100px;

  white-space: nowrap;
  height: fit-content;
  width: fit-content;
}

/*Auth Page*/
.auth-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.auth-form {
  width: 60vw;
  padding: 3vmin;
  background-color: var(--white);
  /* border: .1vmin solid var(--tan-3);*/
  border-radius: 1vmin;
}






















/*About Us Page*/
.aboutUs-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box-container{
  width:100vw;
  margin-bottom: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}



/*About*/
.about{
  width:85%;
  padding:15px 10px 0px 10px;

  color:var(--text-black);
  font-size: larger;


  border-radius: 15px;
  background-color: rgba(196, 193, 238, .7);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
  rgba(94, 87, 87, 0.3) 0px 18px 36px -18px inset;

}
/*Reviews*/
.reviews-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width:100vw
}
.reviews{
  /* margin-left: auto;
  margin-right:auto ; */
  display: flex;
  color: var(--text-dark);
  border-radius: 15px;
  padding: 40px 10px 0 10px ;
  background-color: rgba(196, 193, 238, .7);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
  rgba(94, 87, 87, 0.3) 0px 18px 36px -18px inset;
width: 90%;

}
.WidgetBackground__Content-sc-1ho7q3r-2>a{
  display: none !important;
}
.ciCnpO>a{
  display: none !important;
}
/*Galery*/
.container {
  width: 90%;
  margin: 2rem auto; 
}
.virtual-tour{
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  width: 80%;
  height: 80vh;
              
}

.gallery {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(10, 5vw);

  row-gap: 0.025rem;
  column-gap:0.5rem ;
  cursor: pointer;

}

.gallery__img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: block; 
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.gallery__item--1 {

  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
}

.gallery__item--2 {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 3;
}

.gallery__item--3 {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 7;
}

.gallery__item--4 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 7;
}

.gallery__item--5 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 7;
  grid-row-end: 11;
}

.gallery__item--6 {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 6;
  grid-row-end: 11;
}
.gallery__item--6>img {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 6;
  grid-row-end: 11;
  border-radius:0 0 8px 8px;
}
.gallery__item--7{
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 11;
  grid-row-end: 13;
}
.gallery__item--8{
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 11;
  grid-row-end: 12;
}

.gallery__item--9{
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 12;
  grid-row-end: 13;
}

.gallery__item--10{
  grid-column-start: 1;
  grid-column-end: 9;
  grid-row-start: 13;
  grid-row-end: 14;
}


.gallery__item--11 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 14;
  grid-row-end: 15;
}
.gallery__item--12 {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 14;
  grid-row-end: 15;
}
.gallery__item--13{
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 15;
  grid-row-end: 16;
}

.gallery__item--14 {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 15;
  grid-row-end: 16;
}


.map{
  width:100%;
  height:500px
}























/*Contact Us Page*/
.contactUs-page{
  display: flex;
}

.contactUs-text{
  margin-left: 10px;
  margin-top: 10px;
}
.contactUs-img>img{
  margin-left: 10px;
  width: 40vmax;
  border-radius: 10px;
}

.email-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.emailUs-form{
  display: flex;
  flex-direction: column;

  /* width: 50%; */
  background-color: var(--white);
  border-radius: 1vmin;
  padding:20px;
  margin-bottom: 50px;
  margin-top: 0px;
}

.emailUs-form>input{
  width:90%;
  /* align-self:flex-start; */
  margin-bottom: 10px;
}

.emailUs-form>textarea{
  width:90%;
  margin-bottom: 10px;
  align-self: center;
}































/*Availability idicator*/
.dot-online {
  height: 15px;
  width: 15px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
.dot-offline {
  height: 15px;
  width: 15px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}











/* Switch CheckBox*/
.checkr {
  display: flex;
  justify-content: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.available {
  position: relative;
  display: inline-block;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(117, 117, 117);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--text-dark);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}














/*LOADING Animation*/

.loader {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  /* z-index: 99999; */
}

.loader-inner {
  bottom: 0;
  height: 60px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
}

.loader-line-wrap {
  animation: spin 2000ms cubic-bezier(0.175, 0.885, 0.32, 1.275) infinite;
  box-sizing: border-box;
  height: 50px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform-origin: 50% 100%;
  width: 100px;
}

.loader-line {
  border: 4px solid transparent;
  border-radius: 100%;
  box-sizing: border-box;
  height: 100px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
}

.loader-line-wrap:nth-child(1) {
  animation-delay: -50ms;
}
.loader-line-wrap:nth-child(2) {
  animation-delay: -100ms;
}
.loader-line-wrap:nth-child(3) {
  animation-delay: -150ms;
}
.loader-line-wrap:nth-child(4) {
  animation-delay: -200ms;
}
.loader-line-wrap:nth-child(5) {
  animation-delay: -250ms;
}

.loader-line-wrap:nth-child(1) .loader-line {
  border-color: hsl(0, 80%, 60%);
  height: 90px;
  width: 90px;
  top: 7px;
}

.loader-line-wrap:nth-child(2) .loader-line {
  border-color: hsl(60, 80%, 60%);
  height: 76px;
  width: 76px;
  top: 14px;
}
.loader-line-wrap:nth-child(3) .loader-line {
  border-color: hsl(120, 80%, 60%);
  height: 62px;
  width: 62px;
  top: 21px;
}
.loader-line-wrap:nth-child(4) .loader-line {
  border-color: hsl(180, 80%, 60%);
  height: 48px;
  width: 48px;
  top: 28px;
}
.loader-line-wrap:nth-child(5) .loader-line {
  border-color: purple;
  height: 34px;
  width: 34px;
  top: 35px;
}

@keyframes spin {
  0%,
  15% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}









/*Image Stack*/
.stack {
  cursor: pointer;
  margin: 50px auto;
  position: relative;
}
.stack:before {
  background: #eff4de;
  top: -10px;
  left: -15px;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  transform: rotate(-5deg);
}
.stack:after {
  background: #768590;
  top: 5px;
  left: 0px;
  -webkit-transform: rotate(4deg);
  -moz-transform: rotate(4deg);
  -o-transform: rotate(4deg);
  -ms-transform: rotate(4deg);
  transform: rotate(4deg);
}
.stack,
.stack:before,
.stack:after {
  border: 6px solid #fff;
  width: 250px;
  height: 180px;
  -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}
.stack:before,
.stack:after {
  content: "";
  position: absolute;
  z-index: -1;
}
.stack > span {
  position: relative;
  top: 10px;
  margin: 0 auto;
  width: 90%;
  color: #949494;
  font-size: 0.8em;
  padding: 10px;
  display: block;
  background: #fafafa;
  text-align: center;
  z-index: -10;
  -webkit-transform: rotate(2deg);
  -moz-transform: rotate(2deg);
  -o-transform: rotate(2deg);
  -ms-transform: rotate(2deg);
  transform: rotate(2deg);
  -webkit-border-radius: 3px;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27),
    0 0 60px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.06) inset;
}










/*Arrow*/

.return {
  display: flex;
  margin-left: 20px;
  flex-direction: column;
  /* flex-direction: row;
  justify-content: center; */
  align-items: center;

  justify-content: flex-start;
}

.left > i {
  border: solid var(--title-light);
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);

  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6px;
  margin-bottom: 1px;
}

.back {
  align-self: flex-start;
}



@media (orientation:portrait){
   .emailUs-form{
    width:80%
  }
  .info, .quals>div{
    width:90%
  }
  .quals-list{
    text-align: start;
  }
  .gallery{
      grid-template-rows: repeat(10, 10vw);
  }
  .about {
    width: 95%
  }
  .virtual-tour{
    width:95%
  }
  .container{
    width:95%
  }
}